import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CampaignDetailsList, CampaignChannelDetailsList, CampaignChannelDetails, CampaignDetails } from "api/models/gamification-service/gamification_pb";

interface CampaignDefaultState {
  campaigns: {
    loading: boolean;
    campaignList: Array<CampaignDetails.AsObject>;
  };
  campaignChannels: {
    loading: boolean;
    campaignChannelList: Array<CampaignChannelDetails.AsObject>;
  };
}

const initialState: CampaignDefaultState = {
  campaigns: {
    loading: true,
    campaignList: [],
  },
  campaignChannels: {
    loading: true,
    campaignChannelList: [],
  },
};

export const campaignSlice = createSlice({
    name: "campaign",
    initialState,
    reducers: {
      campaignListLoading: (state, action: PayloadAction<boolean>) => {
        state.campaigns.loading = action.payload;
      },
      campaignListUpdate: (
        state,
        action: PayloadAction<Array<CampaignDetails.AsObject>>
      ) => {
        state.campaigns.loading = false;
        state.campaigns.campaignList = action.payload;
      },
      campaignChannelListLoading: (state, action: PayloadAction<boolean>) => {
        state.campaignChannels.loading = action.payload;
      },
      campaignChannelListUpdate: (
        state,
        action: PayloadAction<Array<CampaignChannelDetails.AsObject>>
      ) => {
        state.campaignChannels.loading = false;
        state.campaignChannels.campaignChannelList = action.payload;
      },
    },
  });

export const { campaignListLoading, campaignListUpdate, campaignChannelListLoading, campaignChannelListUpdate } = campaignSlice.actions;

export default campaignSlice.reducer;
