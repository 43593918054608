import { grpc } from "@improbable-eng/grpc-web";
import {
  Empty,
  EssentialDetailsResponse,
  GetAllKeyLocationCategoriesDetails,
  GetAllKeyLocationsResponse,
  GetAllLostOrFoundItemDetailsRequest,
  getAllLostAndFoundDetailsResponse,
  GetAllPublishAnnouncementResponse,
  GetAllScheduleEventRequest,
  GetAllScheduleEventResponse,
  RequestPageable,
  GetAllArchivesEventsDetails,
  GetAllArchivesSubEventsDetails,
  TagsList,
  RequestForGetContentWithLocaleSupport,
  ListOfContent,
  ListOfCard,
  GetAllCardsRequest,
  ListOfWidgetOrder,
  WidgetOrderRequest,
} from "api/models/hfn-events-service/hfn-events_pb";
import {RequestPageable as CommonRequestPageable} from "api/models/hfn-events-service/common/common_pb";
import { HFNEventsService } from "api/models/hfn-events-service/hfn-events_pb_service";
import { firebaseAuth } from "config/firebase";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import moment from "moment";
import { AppThunk } from "redux/store/store";
import { HFN_EVENTS_SERVICE_HOST } from "api/serviceEndpoints";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import {
  announcementsList,
  archiveListLoading,
  archiveListLoadingError,
  archiveSubEventListLoading,
  archiveSubEventListLoadingError,
  daajiMessageListError,
  daajiMessageListLoading,
  essesntialsList,
  homeBannerListError,
  homeBannerListLoading,
  keyLocationCategories,
  keyLocationsList,
  lostAndFoundList,
  lostAndFoundLoading,
  scheduledEventsList,
  setAnnouncementsListLoading,
  setArchiveList,
  setArchiveSubEventList,
  setDaajiMessageList,
  setEventsListLoading,
  setHomeBannerList,
  setKeyLocationListLoading,
  setTagsList,
  setYatraGardenList,
  tagsListError,
  tagsListLoading,
  yatraGardenListError,
  yatraGardenListLoading,
  homeScreenCardsLoading, 
  homeScreenCardsList,
  setEssentialsListLoading,
} from "redux/reducers/bhandaraReducer";
import { ContentCategory, Screens } from "api/models/hfn-events-service/common/common_pb";

export const getAllScheduleEventBetweenDates =
  (
    startDate: string,
    endDate: string,
    pageSize: number,
    pageNumber: number
  ): AppThunk =>
  async (dispatch) => {
    try {
      const end = moment(endDate).endOf("day").format("");

      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: GetAllScheduleEventRequest =
        new GetAllScheduleEventRequest();
      const currentGoogleTimeStamp: Timestamp = new Timestamp();
      const endGoogleTimeStamp: Timestamp = new Timestamp();

      currentGoogleTimeStamp.fromDate(new Date(startDate));
      endGoogleTimeStamp.fromDate(new Date(end));

      const pagination: RequestPageable = new RequestPageable();

      pagination.setPagesize(pageSize);
      pagination.setPagenumber(pageNumber);

      reqBody.setScheduledstarttime(currentGoogleTimeStamp);
      reqBody.setScheduledendtime(endGoogleTimeStamp);
      reqBody.setPageable(pagination);
      grpc.unary(HFNEventsService.GetAllScheduleEvent, {
        host: HFN_EVENTS_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<GetAllScheduleEventResponse>) => {
          const { status, message } = res;
          if (status === grpc.Code.OK && message) {
            dispatch(
              scheduledEventsList(message.toObject().scheduledeventdetailsList)
            );
            console.log("scheduled events list: ", message.toObject().scheduledeventdetailsList);
            dispatch(setEventsListLoading(false));
          }
        },
      });
    } catch (err) {
      console.log(err);
      dispatch(setEventsListLoading(true));
    }
  };

export const getAllKeyLocations =
  (pageSize: number, pageNumber: number): AppThunk =>
  async (dispatch) => {
    const jwtToken = await firebaseAuth.currentUser?.getIdToken();
    const reqBody: RequestPageable = new RequestPageable();
    reqBody.setPagesize(pageSize);
    reqBody.setPagenumber(pageNumber);
    grpc.unary(HFNEventsService.GetAllKeyLocations, {
      host: HFN_EVENTS_SERVICE_HOST,
      request: reqBody,
      metadata: {
        Authorization: `Bearer ${jwtToken}`,
      },
      onEnd: (res: UnaryOutput<GetAllKeyLocationsResponse>) => {
        const { status, message, statusMessage } = res;
        console.log("fetch key locations status", status, statusMessage);
        if (status === grpc.Code.OK && message) {
          if (message) {
            console.log(
              "keylocation list",
              message.toObject().keylocationdetailsList
            );
            dispatch(
              keyLocationsList(message.toObject().keylocationdetailsList)
            );
            dispatch(setKeyLocationListLoading(false));
          }
        } else {
          console.log("error in fetching key locations");
          dispatch(setKeyLocationListLoading(true));
        }
      },
    });
  };

export const getAllLocationCategories = (): AppThunk => async (dispatch) => {
  const jwtToken = await firebaseAuth.currentUser?.getIdToken();
  const reqBody = new Empty();
  grpc.unary(HFNEventsService.GetAllKeyLocationCategories, {
    host: HFN_EVENTS_SERVICE_HOST,
    request: reqBody,
    metadata: {
      Authorization: `Bearer ${jwtToken}`,
    },
    onEnd: (res: UnaryOutput<GetAllKeyLocationCategoriesDetails>) => {
      const { status, message } = res;
      if (status === grpc.Code.OK && message) {
        if (message) {
          console.log(
            "category list",
            message.toObject().keylocationcategorydetailsList
          );
          dispatch(
            keyLocationCategories(
              message.toObject().keylocationcategorydetailsList
            )
          );
        }
      } else {
        console.log("error in fetching location categories");
      }
    },
  });
};

export const getAllAnnouncements =
  (pageSize: number, pageNumber: number): AppThunk =>
  async (dispatch) => {
    const jwtToken = await firebaseAuth.currentUser?.getIdToken();
    const reqBody: RequestPageable = new RequestPageable();
    reqBody.setPagesize(pageSize);
    reqBody.setPagenumber(pageNumber);
    grpc.unary(HFNEventsService.GetAllPublishAnnouncement, {
      host: HFN_EVENTS_SERVICE_HOST,
      request: reqBody,
      metadata: {
        Authorization: `Bearer ${jwtToken}`,
      },
      onEnd: (res: UnaryOutput<GetAllPublishAnnouncementResponse>) => {
        const { status, message, statusMessage } = res;

        if (status === grpc.Code.OK) {
          dispatch(
            announcementsList(message?.toObject().announcementdetailsList)
          );
          dispatch(setAnnouncementsListLoading(false));
        } else {
          console.log("error in fetching announcements", statusMessage, status);
          if (
            status === grpc.Code.InvalidArgument &&
            statusMessage ===
              "javassist.NotFoundException: There is no any LostAndFoundItem Details Found In DB"
          ) {
            dispatch(announcementsList([]));
          }
        }
      },
    });
  };

export const getAllEssentials =
  (pageSize: number, pageNumber: number): AppThunk =>
  async (dispatch) => {
    dispatch(setEssentialsListLoading(true));
    const jwtToken = await firebaseAuth.currentUser?.getIdToken();
    const reqBody: RequestPageable = new RequestPageable();
    reqBody.setPagesize(pageSize);
    reqBody.setPagenumber(pageNumber);
    grpc.unary(HFNEventsService.GetAllEssentialDetails, {
      host: HFN_EVENTS_SERVICE_HOST,
      request: reqBody,
      metadata: {
        Authorization: `Bearer ${jwtToken}`,
      },
      onEnd: (res: UnaryOutput<EssentialDetailsResponse>) => {
        const { status, message, statusMessage } = res;
        if (status === grpc.Code.OK && message) {
          console.log("essential details", message.toObject());
          dispatch(essesntialsList(message.toObject()));
        } else {
          console.error("Error in getting essential details\n", statusMessage);
          dispatch(setEssentialsListLoading(false));
        }
      },
    });
  };

export const getAllLostAndFoundItems =
  (pageSize: number, pageNumber: number, itemType: 0 | 1 | 2 | 3): AppThunk =>
  async (dispatch) => {
    dispatch(lostAndFoundLoading(true));
    const jwtToken = await firebaseAuth.currentUser?.getIdToken();
    const pageable: RequestPageable = new RequestPageable();
    pageable.setPagenumber(pageNumber);
    pageable.setPagesize(pageSize);

    const reqBody: GetAllLostOrFoundItemDetailsRequest =
      new GetAllLostOrFoundItemDetailsRequest();
    reqBody.setPageable(pageable);
    reqBody.setBhandarahelplinetype(itemType);
    console.log(reqBody.toObject());

    grpc.unary(HFNEventsService.GetAllLostAndFoundDetails, {
      host: HFN_EVENTS_SERVICE_HOST,
      request: reqBody,
      metadata: {
        Authorization: `Bearer ${jwtToken}`,
      },
      onEnd: (res: UnaryOutput<getAllLostAndFoundDetailsResponse>) => {
        const { status, message, statusMessage } = res;
        if (status === grpc.Code.OK && message) {
          dispatch(lostAndFoundList(message.toObject()));
          dispatch(lostAndFoundLoading(false));
        } else {
          console.error(
            "Error in getting lost and found items\n",
            statusMessage
          );
        }
        dispatch(lostAndFoundLoading(false));
      },
    });
  };

export const getAllArchives =
  (pageSize: number, pageNumber: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(archiveListLoading());
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: RequestPageable = new RequestPageable();
      reqBody.setPagesize(pageSize);
      reqBody.setPagenumber(pageNumber);
      grpc.unary(HFNEventsService.GetAllArchivesEvents, {
        host: HFN_EVENTS_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<GetAllArchivesEventsDetails>) => {
          const { status, message, statusMessage } = res;
          console.log("archive status", status, statusMessage);
          if (status === grpc.Code.OK && message) {
            if (message) {
              console.log("archive list", message.toObject());
              dispatch(setArchiveList(message.toObject()));
            }
          } else {
            console.log("error in fetching archive");
            dispatch(archiveListLoadingError());
          }
        },
      });
    } catch (err) {
      dispatch(archiveListLoadingError());
      console.log(err);
    }
  };
export const getAllArchiveSubEvents =
  (pageSize: number, pageNumber: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(archiveSubEventListLoading());
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: RequestPageable = new RequestPageable();
      reqBody.setPagesize(pageSize);
      reqBody.setPagenumber(pageNumber);
      grpc.unary(HFNEventsService.GetAllArchivesSubEvents, {
        host: HFN_EVENTS_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<GetAllArchivesSubEventsDetails>) => {
          const { status, message, statusMessage } = res;
          console.log("archivesub event status", status, statusMessage);
          if (status === grpc.Code.OK && message) {
            if (message) {
              console.log("archive sub event list", message.toObject());
              dispatch(setArchiveSubEventList(message.toObject()));
            }
          } else {
            console.log("error in fetching archive sub event");
            dispatch(archiveSubEventListLoadingError());
          }
        },
      });
    } catch (err) {
      dispatch(archiveSubEventListLoadingError());
      console.log(err);
    }
  };

export const getAllBhandaraTags =
  (pageSize: number, pageNumber: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(tagsListLoading(true));
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: RequestPageable = new RequestPageable();
      reqBody.setPagesize(pageSize);
      reqBody.setPagenumber(pageNumber);
      grpc.unary(HFNEventsService.GetAllTags, {
        host: HFN_EVENTS_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<TagsList>) => {
          const { status, message, statusMessage } = res;
          //console.log("archive status", status, statusMessage);
          if (status === grpc.Code.OK && message) {
            if (message) {
              console.log("hfn tags list", message.toObject());
              dispatch(setTagsList(message.toObject()));
            }
          } else {
            console.log("error in fetching archive");
            dispatch(tagsListError());
          }
        },
      });
    } catch (err) {
      dispatch(tagsListError());
      console.log(err);
    }
  };
export const getAllDaajiMessage =
  (pageSize: number, pageNumber: number, language: any): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(daajiMessageListLoading(true));
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const pagable: RequestPageable = new RequestPageable();
      pagable.setPagesize(pageSize);
      pagable.setPagenumber(pageNumber);
      const reqBody: RequestForGetContentWithLocaleSupport =
        new RequestForGetContentWithLocaleSupport();
      reqBody.setContentcategory(ContentCategory.DAAJI_MESSAGES);
      reqBody.setPageable(pagable);
      reqBody.setSelectedlanguage(language);
      console.log("reqbody", reqBody.toObject());
      grpc.unary(HFNEventsService.GetBhandaraContentByCategory, {
        host: HFN_EVENTS_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<ListOfContent>) => {
          const { status, message, statusMessage } = res;
          console.log("archive status", status, statusMessage);
          if (status === grpc.Code.OK && message) {
            if (message) {
              console.log("Daajis message list", message.toObject());
              const data: any = message.toObject();
              dispatch(daajiMessageListLoading(false));

              dispatch(setDaajiMessageList(data));
            }
          } else {
            console.log("error in fetching Daajis message");
            dispatch(daajiMessageListError());
          }
        },
      });
    } catch (err) {
      dispatch(daajiMessageListError());
      console.log("error in fetching Daajis message", err);
    }
  };

  export const getAllHomeBanner = (pageSize: number, pageNumber: number, language: any): AppThunk =>
    async (dispatch) => {
      try {
        dispatch(homeBannerListLoading(true));
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        const pagable: CommonRequestPageable = new CommonRequestPageable();
        pagable.setPagesize(pageSize);
        pagable.setPagenumber(pageNumber);
        const reqBody: GetAllCardsRequest =
          new GetAllCardsRequest();
        reqBody.setPageable(pagable);
        reqBody.setSelectedlanguage(language);
        reqBody.setIsadmin(true);
        grpc.unary(HFNEventsService.GetAllCards, {
          host: HFN_EVENTS_SERVICE_HOST,
          request: reqBody,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<ListOfCard>) => {
            const { status, message, statusMessage } = res;        
            if (status === grpc.Code.OK && message) {
              if (message) {
                const data: any = message.toObject();
                dispatch(homeBannerListLoading(false));
  
                dispatch(setHomeBannerList(data));
              }
            } else {
              dispatch(homeBannerListError());
            }
          },
        });
      } catch (err) {
        dispatch(homeBannerListError());
      }
    };

export const getAllYatraGarden =
  (pageSize: number, pageNumber: number, language: any): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(yatraGardenListLoading(true));
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const pagable: RequestPageable = new RequestPageable();
      pagable.setPagesize(pageSize);
      pagable.setPagenumber(pageNumber);
      const reqBody: RequestForGetContentWithLocaleSupport =
        new RequestForGetContentWithLocaleSupport();
      reqBody.setContentcategory(ContentCategory.YATRA_GARDEN);
      reqBody.setPageable(pagable);
      reqBody.setSelectedlanguage(language);
      console.log("reqbody", reqBody.toObject());
      grpc.unary(HFNEventsService.GetBhandaraContentByCategory, {
        host: HFN_EVENTS_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<ListOfContent>) => {
          const { status, message, statusMessage } = res;
          console.log("archive status", status, statusMessage);
          if (status === grpc.Code.OK && message) {
            if (message) {
              console.log("Yatra Garden list", message.toObject());
              const data = message.toObject();
              dispatch(setYatraGardenList(data));
            }
          } else {
            console.log("error in fetching Yatra Garden");
            dispatch(yatraGardenListError());
          }
        },
      });
    } catch (err) {
      dispatch(yatraGardenListError());
      console.log("error in fetching yatra garden", err);
    }
  };

  export const getAllHomeScreenCards = (containerscreen: 0 | 1 | 2 | 3 | 4, includeallwidgets: boolean): AppThunk => async (dispatch) => {
    try {
      dispatch(homeScreenCardsLoading(true));
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: WidgetOrderRequest = new WidgetOrderRequest();
      reqBody.setContainerscreen(containerscreen);
      reqBody.setIncludeallwidgets(includeallwidgets);
      grpc.unary(HFNEventsService.FetchWidgetOrderByScreen, {
        host: HFN_EVENTS_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<ListOfWidgetOrder>) => {
          const { status, message, statusMessage } = res;
          console.log("home screen cards", message?.toObject());
          if (status === grpc.Code.OK && message) {
            dispatch(homeScreenCardsList(message.toObject()));
            dispatch(homeScreenCardsLoading(false));
          } else {
            console.error("error in fetching home screen cards", statusMessage, status);
            dispatch(homeScreenCardsLoading(false));
          }
        },
      });
    } catch (err) {
      dispatch(homeScreenCardsLoading(false));
      console.error("error in request to fetch home screen cards", err);
    }
  };  