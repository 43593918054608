import { grpc } from "@improbable-eng/grpc-web";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import { CampaignChannelDetailsList, CampaignDetails, CampaignDetailsList, ID } from "api/models/gamification-service/gamification_pb";
import { GamificationService } from "api/models/gamification-service/gamification_pb_service";
import { GAMIFICATION_SERVICE_HOST } from "api/serviceEndpoints";

import { firebaseAuth } from "config/firebase";
import { campaignChannelListLoading, campaignChannelListUpdate, campaignListLoading, campaignListUpdate } from "redux/reducers/campaignReducer";
import { AppThunk } from "redux/store/store";


export const getCampaignList = (): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(campaignListLoading(true));
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: CampaignDetails = new CampaignDetails();
      reqBody.setIsactive(true);
      grpc.unary(GamificationService.GetAllCampaign, {
        host: GAMIFICATION_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<CampaignDetailsList>) => {
          const { status, message, statusMessage } = res;
          console.log("campaign list", message?.toObject());
          if (status === grpc.Code.OK && message) {
            dispatch(campaignListUpdate(message.toObject().campaigndetailsList));
          } else {
            dispatch(campaignListLoading(false));
            console.error("error in fetching campaign list ", message);
          }
        },
      });
    } catch (error) {
      dispatch(campaignListLoading(false));
      console.error(error);
    }
  };


  export const getCampaignChannelList = (id: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(campaignChannelListLoading(true));
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: ID = new ID();
      reqBody.setId(id);
      grpc.unary(GamificationService.GetAllCampaignChannel, {
        host: GAMIFICATION_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<CampaignChannelDetailsList>) => {
          const { status, message, statusMessage } = res;
          console.log("campaign channel list", message?.toObject());
          if (status === grpc.Code.OK && message) {
            dispatch(campaignChannelListUpdate(message.toObject().campaignchanneldetailsList));
          } else {
            dispatch(campaignChannelListLoading(false));
            console.error("error in fetching campaign channel list ", message);
          }
        },  
      });
    } catch (error) {
      dispatch(campaignChannelListLoading(false));
      console.error(error);
    }
  };