import {
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    Container,
    Divider,
    Grid,
    Grow,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
  } from "@mui/material";
  import React, { ReactElement, useEffect, useState, useMemo } from "react";
  import AddIcon from "@mui/icons-material/Add";
  import { ListSkeleton } from "components/listSkeleton";
  import RefreshIcon from "@mui/icons-material/Refresh";
  import style from "./index.module.scss";
  import { useDispatch, useSelector } from "react-redux";
  import { RootState } from "redux/store/store";
  import { CampaignForm } from "./CampaignsForm";
import { getCampaignChannelList, getCampaignList } from "redux/actions/campaignActions";
import { campaignChannelListUpdate } from "redux/reducers/campaignReducer";

const Channels = {
  0: 'Unknown',
  1: 'SMS',
  2: 'Push',
  3: 'Email',
};
  
  interface State {
    editing?: boolean;
    editItem?: any;
  }

  const initialState: State = {
    editing: false,
    editItem: null,
  };
  
  export default function CampaignsScreen(): ReactElement {
    const dispatch = useDispatch();
    const campaignList = useSelector(
      (state: RootState) => state.campaign.campaigns
    );
    const campaignChannels = useSelector(
      (state: RootState) => state.campaign.campaignChannels
    );
  
    const languages = useSelector((state: RootState) => state.app.languages);
    const [language, setLanguage] = useState<any>(1);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [states, setStates] = useState<State>(initialState);
    const [searchCampaign, setSearchCampaign] = useState("");
    const [searchData, setSearchData] = useState<any[]>([]);
  
    const onClickBack = () => {
      setCurrentIndex(0);
      setSearchCampaign("");
      setStates(initialState);
      dispatch(getCampaignChannelList(campaignList.campaignList[0].id));
    };

    useEffect(() => {
      if(campaignList.campaignList.length === 0){
        dispatch(getCampaignList());
      }
      if(campaignList.campaignList.length > 0){
        dispatch(getCampaignChannelList(campaignList.campaignList[0].id));
      }

      return () => {
        dispatch(campaignChannelListUpdate([]));
      }
    }, [dispatch, campaignList]);

    const localizedData = useMemo(() => {
      if(campaignChannels.campaignChannelList.length === 0 || campaignChannels.loading){
        return [];
      }
      const Language = languages.find((lang: any) => lang.code === language);
      const langName = Language?.name === "de" ? "defaultcontent" : Language?.name;
      // console.log("langName ", langName);
      return campaignChannels.campaignChannelList.map((item: any) => ({
        ...item,
        localizedTitle: item.notificationtitle[`${langName}`],
        localizedTemplate: item.template[`${langName}`],
        langName: langName
      }));
    }, [language, campaignChannels]);

    const renderCampaignsList = (data: any) => {
      return (
        <div>
          {data.length ? (
            <>
              <Grid
                style={{ backgroundColor: "transparent" }}
                className="d-flex py-2 pl-3 align-items-center"
              >
                <Grid className="d-flex flex-grow-1">
                  <TextField
                    type="search"
                    fullWidth
                    value={searchCampaign}
                    variant="outlined"
                    placeholder="Search campaign by name"
                    size="small"
                    onChange={(e) => {
                      setSearchCampaign(e.target.value);
                      const filteredData = data.filter((item: any) =>
                        item.localizedTitle
                          .toLowerCase()
                          .includes(e.target.value.toLowerCase())
                      );
                      setSearchData(filteredData);
                    }}
                    className="mr-2"
                  />
                </Grid>
                <Grid className="d-flex align-items-center">
                  <IconButton
                    onClick={() => {
                      dispatch(getCampaignChannelList(campaignList.campaignList[0].id));
                    }}
                    size="large"
                  >
                    <Tooltip title="Refresh">
                      <RefreshIcon />
                    </Tooltip>
                  </IconButton>
                </Grid>
              </Grid>
              <TableContainer
                style={{ maxHeight: "400px" }}
                className={style.scrollbar}
              >
                <Table stickyHeader aria-label="sticky table" size="small">
                  <TableHead>
                    <TableRow style={{ fontWeight: "bold" }}>
                      <TableCell
                        align="left"
                        sx={{
                          width: "30%",
                        }}
                      >
                        Title
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          width: "20%",
                        }}
                      >
                        Channel
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(searchCampaign !== "" ? searchData : data).map(
                      (item: any, x: any) => (
                        <React.Fragment key={x}>
                          <TableRow
                            sx={{ cursor: "pointer", height: "50px" }}
                            hover
                            onClick={() => {
                              setStates({
                                editing: true,
                                editItem: item,
                              });
                              setCurrentIndex(1);
                            }}
                          >
                            <TableCell align="left">
                              {item.localizedTitle ? (
                                item.localizedTitle
                              ) : (
                                <Chip
                                  variant="filled"
                                  label="Name not available"
                                />
                              )}
                            </TableCell>
                            <TableCell align="left">
                              {Channels[item.channel] || 'Unknown'}
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <Box height={350}>
              <Grid
                container
                style={{
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="h6" color="textSecondary">
                  No campaigns to show
                </Typography>
              </Grid>
            </Box>
          )}
        </div>
      );
    }
  
    return (
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Card className="mt-3 mb-5">
              <Grow
                in={currentIndex === 0}
                mountOnEnter
                unmountOnExit
                style={{ transformOrigin: "0 0 0" }}
                {...(currentIndex === 0 ? { timeout: 1000 } : {})}
              >
                <div>
                  <Grid container className="align-items-center p-3" spacing={2}>
                    <Grid item>
                      <Typography variant="h6">Birthday campaign</Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          const Language = languages.find((lang: any) => lang.code === language);
                          const langName = Language?.name === "de" ? "defaultcontent" : Language?.name;
                          setStates({
                            editing: false,
                            editItem: {
                              campaignid: campaignList.campaignList[0].id,
                              langName: langName,
                            },
                          });
                          setCurrentIndex(1);
                        }}
                        startIcon={<AddIcon />}
                      >
                        Add Channel
                      </Button>
                    </Grid>
                  </Grid>
                  {/* <Grid item xs={6} sm={4} md={4} lg={3}>
                    <FormControl variant="outlined" className="m-3" fullWidth>
                      <InputLabel id="language-select">Language</InputLabel>
                      {languages && (
                        <Select
                          defaultValue=""
                          labelId="language-select"
                          value={language}
                          label="Language"
                          onChange={(e) => {
                            setLanguage(e.target.value as number);
                          }}
                          MenuProps={MenuProps}
                        >
                          {languages.map((lang: any, i: any) => (
                            <MenuItem key={i} value={lang.code}>
                              {lang.displayName}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </FormControl>
                  </Grid> */}
                  <Divider />
                  <CardContent>
                    <Paper>
                      { campaignChannels.loading || campaignList.loading
                        ? ListSkeleton()
                        : renderCampaignsList(localizedData)}
                    </Paper>
                  </CardContent>
                </div>
              </Grow>
              <Grow
                in={currentIndex === 1}
                mountOnEnter
                unmountOnExit
                style={{ transformOrigin: "0 0 0" }}
                {...(currentIndex === 1 ? { timeout: 1000 } : {})}
              >
                <CampaignForm
                  isEditing={states.editing}
                  editItem={states.editItem}
                  onClickBack={onClickBack}
                  lang={language}
                />
              </Grow>
            </Card>
          </Grid>
        </Grid>
      </Container>
    );
  }