import React, { ReactElement, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { firebaseAuth } from "config/firebase";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { GamificationService } from "api/models/gamification-service/gamification_pb_service";
import { CampaignChannelDetails, ID, Status } from "api/models/gamification-service/gamification_pb";
import { grpc } from "@improbable-eng/grpc-web";
import { GAMIFICATION_SERVICE_HOST } from "api/serviceEndpoints";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import { Locale } from "api/models/gamification-service/common/common_pb";
import { useDispatch } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";

interface CampaignProps {
  onClickBack: any;
  isEditing: any;
  editItem: any;
  lang: 0 | 1 | 2 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21;
}

const PREFIX = "CampaignForm";

const classes = {
  root: `${PREFIX}-root`,
  imgCardHeader: `${PREFIX}-imgCardHeader`,
  progress: `${PREFIX}-progress`,
  formBtn: `${PREFIX}-formBtn`,
  thumbNail: `${PREFIX}-thumbNail`,
  media: `${PREFIX}-media`,
  actionIcon: `${PREFIX}-actionIcon`,
  paper: `${PREFIX}-paper`,
  toggle: `${PREFIX}-toggle`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    height: 250,
    width: "100%",
  },

  [`& .${classes.imgCardHeader}`]: {
    height: "20%",
    textOverflow: "ellipsis",
  },

  [`& .${classes.progress}`]: {
    color: theme.palette.primary.main,
    position: "absolute",
    left: "50%",
  },

  [`& .${classes.formBtn}`]: {
    marginRight: "20px",
  },

  [`& .${classes.thumbNail}`]: {
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    display: "block",
  },

  [`& .${classes.media}`]: {
    height: "60%",
  },

  [`& .${classes.paper}`]: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    top: `40%`,
    left: `40%`,
  },

  [`& .${classes.toggle}`]: {
    "&.MuiToggleButton-root": {
      padding: "15px 11px",
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
}));

export const CampaignForm = React.forwardRef<HTMLDivElement, CampaignProps>(
  (props, ref): ReactElement => {
    const quillRef = useRef<ReactQuill>(null);
    const dispatch = useDispatch();
    const [content, setContent] = React.useState("");
    const [title, setTitle] = React.useState("");
    const [screenName, setScreenName] = React.useState("");
    const [channel, setChannel] = React.useState<0 | 1 | 2 | 3>(2);
    const [isAdding, setIsAdding] = React.useState(false);
    const [deeplink, setDeeplink] = React.useState("");
    const [color, setColor] = React.useState("#2cd29b"); 
    const [paddingX, setPaddingX] = React.useState("20px");
    const [paddingY, setPaddingY] = React.useState("10px");
    const [snackBar, setSnackBar] = React.useState<any>({
      open: false,
      severity: "success",
      message: "",
    });
    const [day, setDay] = React.useState<number>(7);
    const validateForm = () => {
      if (content.trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Please enter a valid content",
        });
        return false;
      }
      return true;
    };

    useEffect(() => {
      console.log("props.editItem: ", props.editItem);
      if(props.isEditing){
        setContent(props.editItem.localizedTemplate);
        setTitle(props.editItem.localizedTitle);
        setScreenName(props.editItem.screenname);
        setChannel(props.editItem.channel);
        setDeeplink(props.editItem.deeplink);
        setDay(props.editItem.day);
        // Create a temporary div to parse the HTML content
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = props.editItem.localizedTemplate;
        
        // Find all anchor tags
        const anchors = tempDiv.querySelectorAll('a');
        // Find all anchors that have button-like styling
        const buttonAnchors = Array.from(anchors).filter(anchor => {
          const style = anchor.style;
          return style.backgroundColor && 
                 style.display === 'inline-block' && 
                 style.padding && 
                 style.borderRadius === '5px' &&
                 style.textDecoration === 'none';
        });

        // Apply styles to all buttons in the editor after content is loaded
        setTimeout(() => {
          const el = document.getElementsByClassName("ql-editor")?.[0];
          const editorButtons = Array.from(el?.querySelectorAll("a") || []).filter(button => !button.querySelector("img"));
          let anchorIndex = 0;

          if (buttonAnchors.length > 0) {
          for(let i = 0; i < editorButtons?.length; i++){
            const button = editorButtons[i];
            if (button instanceof HTMLAnchorElement) {
              // Only style elements that match our button criteria
              if (button.style.backgroundColor || 
                  (button.style.display === 'inline-block' && 
                   button.style.padding && 
                   button.style.borderRadius === '5px')) {
                button.style.backgroundColor = buttonAnchors[anchorIndex].style.backgroundColor;
                button.style.color = buttonAnchors[anchorIndex].style.color;
                button.style.textDecoration = buttonAnchors[anchorIndex].style.textDecoration;
                button.style.display = buttonAnchors[anchorIndex].style.display;
                button.style.fontFamily = buttonAnchors[anchorIndex].style.fontFamily;
                button.style.fontSize = buttonAnchors[anchorIndex].style.fontSize;
                button.style.padding = buttonAnchors[anchorIndex].style.padding;
                button.style.borderRadius = buttonAnchors[anchorIndex].style.borderRadius;
                anchorIndex++;
              }
              }
            }
          }
        }, 500);
      }
    }, []);

    const handleAddButton = () => {
      const quill = quillRef.current?.getEditor();
      const range = quill?.getSelection();

      const buttonHTML = `
      <a href="https://www.example.com" target="_blank">abcd</a>
    `;

      quill?.clipboard.dangerouslyPasteHTML(range?.index ?? 0, buttonHTML);
      const el = document.getElementsByClassName("ql-editor")?.[0];
      const buttons = el?.querySelectorAll("a");

      const abcdButton = Array.from(buttons).find(button => button.textContent === "abcd");
      if (abcdButton instanceof HTMLAnchorElement) {
        abcdButton.style.backgroundColor = color;
        abcdButton.style.color = "#fff";
        abcdButton.style.textDecoration = "none";
        abcdButton.style.display = "inline-block";
        abcdButton.style.fontFamily = "Helvetica, sans-serif";
        abcdButton.style.fontSize = "16px";
        abcdButton.style.padding = `${paddingY} ${paddingX}`;
        abcdButton.style.borderRadius = "5px";
        abcdButton.textContent = " Donate Now ";
      }
    };

    const handleChannelChange = (event: SelectChangeEvent) => {
      setChannel(+event.target.value as 0 | 1 | 2 | 3);
      setContent("");
    };

    const _addCampaign = () => {
      if (validateForm()) {
        if (props.isEditing) {
          updateCampaign();
        } else {
          createCampaign();
        }
      }
    };

    const deleteCampaign = async () => {
      try {
        setIsAdding(true);
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        const reqBody: ID = new ID();
        reqBody.setId(props.editItem.id);
        grpc.unary(GamificationService.DeleteCampaignChannel, {
          host: GAMIFICATION_SERVICE_HOST,
          request: reqBody,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<Status>) => {
            const { status, message } = res;
            console.log("delete successful", message?.toObject());
            if (status === grpc.Code.OK && message) {
              setIsAdding(false);
              setSnackBar({
                open: true,
                severity: "success",
                message: "Campaign channel deleted successfully",
              });
              setTimeout(() => {
                props.onClickBack();
              }, 500);
            } else {
              setIsAdding(false);
              setSnackBar({
                open: true,
                severity: "error",
                message: "Error in deleting campaign channel",
              });
              console.error("error in deleting campaign channel ", message);
            }
          },
        });
      } catch (error) {
        setIsAdding(false);
        setSnackBar({
          open: true,
          severity: "error",
          message: "Error in deleting campaign channel",
        });
        console.error(error);
      }
    }


    const createCampaign = async () => {
      try {
        setIsAdding(true);
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        const templateLocale = new Locale();
        const notificationTitleLocale = new Locale();
        templateLocale[`set${props.editItem.langName.charAt(0).toUpperCase() + props.editItem.langName.slice(1)}`](content);
        notificationTitleLocale[`set${props.editItem.langName.charAt(0).toUpperCase() + props.editItem.langName.slice(1)}`](title);
        const reqBody: CampaignChannelDetails = new CampaignChannelDetails();
        reqBody.setCampaignid(props.editItem.campaignid);
        reqBody.setChannel(channel);
        reqBody.setDay(day);
        reqBody.setDeeplink(deeplink);
        reqBody.setScreenname(screenName);
        reqBody.setTemplate(templateLocale);
        reqBody.setNotificationtitle(notificationTitleLocale);
        console.log("reqBody: ", reqBody.toObject());
        grpc.unary(GamificationService.CreateCampaignChannel, {
          host: GAMIFICATION_SERVICE_HOST,
          request: reqBody,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<CampaignChannelDetails>) => {
            const { status, message, statusMessage } = res;
            console.log("create successful", message?.toObject());
            if (status === grpc.Code.OK && message) {
              setIsAdding(false);
              setSnackBar({
                open: true,
                severity: "success",
                message: "Campaign channel created successfully",
              });
              setTimeout(() => {
                props.onClickBack();
              }, 500);
            } else {
              setIsAdding(false);
              setSnackBar({
                open: true,
                severity: "error",
                message: "Error in creating campaign channel",
              });
              console.error("error in creating campaign channel ", message);
            }
          },  
        });
      } catch (error) {
        setIsAdding(false);
        setSnackBar({
          open: true,
          severity: "error",
          message: "Error in creating campaign channel",
        });
        console.error(error);
      }
    }

    const updateCampaign = async () => {
      try {
        setIsAdding(true);
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        const templateLocale = new Locale();
        const notificationTitleLocale = new Locale();
        templateLocale[`set${props.editItem.langName.charAt(0).toUpperCase() + props.editItem.langName.slice(1)}`](content);
        notificationTitleLocale[`set${props.editItem.langName.charAt(0).toUpperCase() + props.editItem.langName.slice(1)}`](title);
        const reqBody: CampaignChannelDetails = new CampaignChannelDetails();
        reqBody.setId(props.editItem.id);
        reqBody.setCampaignid(props.editItem.campaignid);
        reqBody.setChannel(channel);
        reqBody.setDay(day);
        reqBody.setDeeplink(deeplink);
        reqBody.setScreenname(screenName);
        reqBody.setTemplate(templateLocale);
        reqBody.setNotificationtitle(notificationTitleLocale);
        console.log("reqBody: ", reqBody.toObject());
        grpc.unary(GamificationService.UpdateCampaignChannel, {
          host: GAMIFICATION_SERVICE_HOST,
          request: reqBody,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<CampaignChannelDetails>) => {
            const { status, message, statusMessage } = res;
            console.log("update successful", message?.toObject());
            if (status === grpc.Code.OK && message) {
              setIsAdding(false);
              setSnackBar({
                open: true,
                severity: "success",
                message: "Campaign channel updated successfully",
              });
              setTimeout(() => {
                props.onClickBack();
              }, 500);
            } else {
              setIsAdding(false);
              setSnackBar({
                open: true,
                severity: "error",
                message: "Error in updating campaign channel",
              });
              console.error("error in fetching campaign channel list ", message);
            }
          },  
        });
      } catch (error) {
        setIsAdding(false);
        setSnackBar({
          open: true,
          severity: "error",
          message: "Error in updating campaign channel",
        });
        console.error(error);
      }
    }

    return (
      <Root ref={ref}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                <Grid container alignItems="center">
                  <Grid>
                    <IconButton onClick={props.onClickBack} size="large">
                      <KeyboardBackspaceIcon color="action" />
                    </IconButton>
                  </Grid>
                  <Grid>
                    <Typography variant="h6">
                      {props.isEditing ? "Edit Campaign" : "Add Campaign"}
                    </Typography>
                    </Grid>
                  </Grid>
                  {props.isEditing && <Button
                    variant="contained"
                    color="secondary" 
                    onClick={deleteCampaign}
                    disabled={isAdding}
                    >
                    <DeleteIcon /> Delete
                  </Button>}
                </Box>
                <Grid container spacing={3} sx={{ mt: 3 }}>
                  <Grid item xs={6}>
                    <TextField
                      label="Notification Title"
                      fullWidth
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Day"
                      fullWidth
                      type="number"
                      value={day}
                      onChange={(e) => setDay(+e.target.value)}
                    />
                  </Grid>
                </Grid>
                <FormControl fullWidth sx={{ mt: 3 }}>
                      <InputLabel>Channel</InputLabel>
                      <Select
                        value={channel.toString()}
                        label="Channel"
                        onChange={handleChannelChange}
                      >
                        <MenuItem value={0}>Unknown</MenuItem>
                        <MenuItem value={1}>SMS</MenuItem>
                        <MenuItem value={2}>Push</MenuItem>
                        <MenuItem value={3}>Email</MenuItem>
                      </Select>
                    </FormControl>
                <TextField
                  label="Deeplink"
                  fullWidth
                  value={deeplink}
                  sx={{ mt: 3}}
                  onChange={(e) => setDeeplink(e.target.value)}
                />
                <TextField
                  label="Screen Name"
                  fullWidth
                  value={screenName}
                  sx={{ my: 3}}
                  onChange={(e) => setScreenName(e.target.value)}
                />
                {channel === 3 && <Typography variant="subtitle2">Template</Typography>}
                {channel === 3 && <Grid sx={{ mt: 1, mb: 2}} container spacing={1} alignItems="center">
                  <Grid item xs={12}>
                    <Typography variant="caption">Button Settings</Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      label="Button Color"
                      type="color"
                      sx={{ width: "90px" }}
                      value={color}
                      onChange={(e) => setColor(e.target.value)}
                      variant="standard"
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      label="Padding X"
                      value={paddingX}
                      sx={{ width: "60px" }}
                      variant="standard"
                      onChange={(e) => setPaddingX(e.target.value)}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      label="Padding Y"
                      value={paddingY}
                      sx={{ width: "60px" }}
                      variant="standard"
                      onChange={(e) => setPaddingY(e.target.value)}
                    />
                  </Grid>
                  <Grid item>
                    <Button variant="contained" disableElevation onClick={handleAddButton}>Add button</Button>
                  </Grid>
                </Grid>}
                {channel === 3 ? <ReactQuill
                  id="content-quill"
                  value={content}
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, 3, 4, 5, 6, false] }],
                      [{ size: ['small', false, 'large', 'huge'] }],
                      ['bold', 'italic', 'underline', 'strike'],
                      [{ align: [] }],
                      ['link'],
                    ]
                  }}
                  theme="snow"
                  placeholder="Write something awesome..."
                  onChange={(value) => {
                    setContent(value);
                  }}
                  ref={quillRef}
                /> : <TextField
                      label="Template"
                      fullWidth
                      value={content}
                      sx={{ mt: 3}}
                      multiline
                      rows={10}
                      onChange={(e) => setContent(e.target.value)}
                      />
              }
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box p={2} display="flex" justifyContent="flex-end">
            <Button
              className={classes.formBtn}
              onClick={() => _addCampaign()}
              color="primary"
              variant="contained"
              disabled={isAdding}
            >
              {props.isEditing ? "Update channel" : "Add channel"}
            </Button>
          </Box>
        </Card>
        <Snackbar
          open={snackBar.open}
          autoHideDuration={10000}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            severity={snackBar.severity}
            onClose={() => {
              setSnackBar({ ...snackBar, open: false });
            }}
          >
            {snackBar.message}
          </Alert>
        </Snackbar>
      </Root>
    );
  }
);
