import {
  Box,
  Button,
  Divider,
  Grid,
  Grow,
  IconButton,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import LocationIcon from "@mui/icons-material/LocationOn";
import style from "./index.module.scss";
import { AddService } from "./AddService";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store/store";
import { getAllEssentials } from "redux/actions/bhandaraActions";
import { ListSkeleton } from "components/listSkeleton";

const TableWrapper = styled("div")({
  margin: "16px",
  height: "100%",
  overflow: "auto",
  border: "1px solid rgba(0, 0, 0, 0.12)",
  borderRadius: "5px",
});

interface State {
  editing?: boolean;
  editItem?: any;
}

export const EssentialServices = React.forwardRef<HTMLDivElement, any>(
  ({}, ref): ReactElement => {
    const initialState: State = {
      editing: false,
      editItem: null,
    };

    const dispatch = useDispatch();
    const essentials = useSelector(
      (state: RootState) => state.bhandara.essestials);
    const [currentScreen, setCurrentScreen] = useState(0);
    const [states, setStates] = useState<State>(initialState);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchService, setSearchService] = useState("");
    const [searchData, setSearchData] = useState<any[]>([]);

    useEffect(() => {
      dispatch(getAllEssentials(rowsPerPage, page));
    }, [dispatch, page, rowsPerPage]);

    const onClickBack = () => {
      setCurrentScreen(0);
      setStates({
        editing: false,
        editItem: null,
      });
    };

    const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
      setSearchService("");
      setSearchData([]);
    };

    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
      setSearchService("");
      setSearchData([]);
    };

    const renderEssentialsList = (data: any) => {
      return (
        <div>
          {data.length ? (
            <>
              <TableContainer
                style={{ maxHeight: "400px" }}
                className={style.scrollbar}
              >
                <Table stickyHeader aria-label="sticky table" size="small">
                  <TableHead>
                    <TableRow style={{ fontWeight: "bold" }}>
                      <TableCell align="left">Service Title</TableCell>
                      <TableCell align="center">Name</TableCell>
                      <TableCell align="center">Mobile Number</TableCell>
                      <TableCell align="center">Whatsapp Number</TableCell>
                      <TableCell align="center">SMS Number</TableCell>
                      <TableCell align="center">Location</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(searchData.length > 0 ? searchData : data).map(
                      (item: any, x: any) => (
                        <React.Fragment key={x}>
                          <TableRow
                          sx={{ cursor: "pointer" }}
                          hover
                          onClick={() => {
                            setStates({
                              editing: true,
                              editItem: item,
                            });
                            setCurrentScreen(1);
                          }}
                        >
                          <TableCell
                            align="left"
                            sx={{
                              borderBottom:
                                data.length - 1 === x ? "none" : "1px solid divider",
                            }}
                          >
                            {item.essentialtitle}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              borderBottom:
                                data.length - 1 === x ? "none" : "1px solid divider",
                            }}
                          >
                            {item.name}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              borderBottom:
                                data.length - 1 === x ? "none" : "1px solid divider",
                            }}
                          >
                            {item.mobilenumber}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              borderBottom:
                                data.length - 1 === x ? "none" : "1px solid divider",
                            }}
                          >
                            {item.whatsappnumber}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              borderBottom:
                                data.length - 1 === x ? "none" : "1px solid divider",
                            }}
                          >
                            {item.smsnumber}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              borderBottom:
                                data.length - 1 === x ? "none" : "1px solid divider",
                            }}
                          >
                            <IconButton
                              disabled={
                                !new RegExp(
                                  /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi
                                ).test(item.directionurl)
                              }
                              onClick={() => window.open(item.directionurl)}
                              size="large"
                              color="primary"
                            >
                              <LocationIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50, 100]}
                component="div"
                count={essentials.essentialsData.pageable?.totalcount || 0}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                style={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}
                className={style.scrollbar}
              />
            </>
          ) : (
            <Box height={350}>
              <Grid
                container
                style={{
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="h6" color="textSecondary">
                  No Essential Services to show
                </Typography>
              </Grid>
            </Box>
          )}
        </div>
      );
    };

    return (
      <div ref={ref}>
        <Grow
          in={currentScreen === 0}
          mountOnEnter
          unmountOnExit
          style={{ transformOrigin: "0 0 0" }}
          {...(currentScreen === 0 ? { timeout: 1000 } : {})}
        >
          <div>
            <Grid
              container
              spacing={2}
              sx={{
                alignItems: "center",
                justifyContent: "space-between",
                padding: " 16px",
              }}
            >
              <Grid item xs={12} sm={6} md={8} lg={6}>
                <Grid
                  container
                  spacing={4}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <Grid item>
                    <Typography variant="h6">Essential Services</Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setCurrentScreen(1)}
                      startIcon={<AddIcon />}
                    >
                      Add a Service
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={6} className="my-1">
                <Grid
                  container
                  alignItems="center"
                  spacing={1}
                  justifyContent="flex-end"
                  //   className={style.date}
                ></Grid>
              </Grid>
            </Grid>
            <Divider />
            <TableWrapper>
              <Paper>
                <Grid
                  item
                  xs={12}
                  style={{ backgroundColor: "transparent" }}
                  className="py-2"
                >
                  <Box display="flex">
                    <TextField
                      type="search"
                      fullWidth
                      value={searchService}
                      variant="outlined"
                      placeholder="Search events by name"
                      size="small"
                      onChange={(e) => {
                        setSearchService(e.target.value);
                        setSearchData(
                          essentials.essentialsData.essentialdetailsList.filter(
                            (item: any) =>
                              item.essentialtitle.toLowerCase().includes(e.target.value.toLowerCase()) ||
                              item.mobilenumber.toString().includes(e.target.value) ||
                              item.whatsappnumber.toString().includes(e.target.value)
                          )
                        );
                      }}
                      className="ml-3"
                    />

                    <Grid>
                      <IconButton
                        onClick={() => {
                          dispatch(getAllEssentials(50, 0));
                        }}
                        size="large"
                      >
                        <Tooltip title="Refresh">
                          <RefreshIcon />
                        </Tooltip>
                      </IconButton>
                    </Grid>
                  </Box>
                </Grid>
                {essentials.loading
                  ? ListSkeleton()
                  : renderEssentialsList(
                      essentials.essentialsData.essentialdetailsList
                    )}
              </Paper>
            </TableWrapper>
          </div>
        </Grow>
        <Grow
          in={currentScreen === 1}
          mountOnEnter
          unmountOnExit
          style={{ transformOrigin: "0 0 0" }}
          {...(currentScreen === 1 ? { timeout: 1000 } : {})}
        >
          <AddService
            isEditing={states.editing}
            editItem={states.editItem}
            onClickBack={onClickBack}
          />
        </Grow>
      </div>
    );
  }
);
