import {
  Button,
  Divider,
  FormControl,
  Grid,
  Grow,
  IconButton,
  MenuItem,
  Paper,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import LocationIcon from "@mui/icons-material/LocationOn";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import moment from "moment";
import style from "./index.module.scss";
import { CreateEvent } from "components/bhandaraEvents/createEvent";
import { useDispatch, useSelector } from "react-redux";
import { getAllScheduleEventBetweenDates } from "redux/actions/bhandaraActions";
import { RootState } from "redux/store/store";
import { ListSkeleton } from "components/listSkeleton";
import { setEventsListLoading } from "redux/reducers/bhandaraReducer";
import { ChevronLeftRounded, ChevronRightRounded } from "@mui/icons-material";

const TableWrapper = styled("div")({
  margin: "16px",
  height: "100%",
  overflow: "auto",
  border: "1px solid rgba(0, 0, 0, 0.12)",
  borderRadius: "5px",
});

interface State {
  editing?: boolean;
  editItem?: any;
}

export const UpcomingEvents = React.forwardRef<HTMLDivElement, any>(
  (props, ref): ReactElement => {
    const initialState = {
      editing: false,
      editItem: null,
    };

    // const regX = new RegExp(
    //   /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi
    // );

    const [currentScreen, setCurrentScreen] = useState(0);
    const [states, setStates] = useState<State>(initialState);
    const [searchEvent] = useState("");
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [startDate, setStartDate] = useState(
      new Date(new Date().setHours(0, 0, 0, 0)).toString()
    );
    const [endDate, setEndDate] = useState(
      new Date(Date.now() + 259200000).toString()
    );

    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state.bhandara.events);
    useEffect(() => {
      dispatch(
        getAllScheduleEventBetweenDates(
          startDate,
          endDate,
          pageSize,
          pageNumber
        )
      );
    }, [dispatch, endDate, startDate, pageSize, pageNumber]);

    const onClickBack = () => {
      setCurrentScreen(0);
      setStates({
        editing: false,
        editItem: null,
      });
    };

    function renderLiveSessionsListBetween() {
      const onclickDirections = (directionUrl: any) => {
        if (directionUrl) {
          window.open(directionUrl);
        }
      };
      return (
        <div>
          <TableContainer
            style={{ maxHeight: "400px" }}
            className={style.scrollbar}
          >
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow style={{ fontWeight: "bold" }}>
                  <TableCell align="center">Event Title</TableCell>
                  <TableCell align="center">Venue</TableCell>
                  <TableCell align="center">Date & Time</TableCell>
                  <TableCell align="center">Directions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.scheduledEventsList.eventsList.map(
                  (item: any, x: any) => {
                    return (
                      <React.Fragment key={x}>
                        <TableRow
                          sx={{ cursor: "pointer", height: "50px" }}
                          hover
                          onClick={() => {
                            setStates({
                              editing: true,
                              editItem: item,
                            });
                            setCurrentScreen(1);
                          }}
                        >
                          <TableCell align="center">{item.title}</TableCell>
                          <TableCell align="center">{item.subtitle}</TableCell>

                          <TableCell align="center">
                            {moment(item.scheduletime.seconds * 1000).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )}
                          </TableCell>
                          <TableCell align="center">
                            <IconButton
                              disabled={
                                !new RegExp(
                                  /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi
                                ).test(item.directionurl)
                              }
                              onClick={() =>
                                onclickDirections(item.directionurl)
                              }
                              size="large"
                              color="primary"
                            >
                              <LocationIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Divider />
          <Grid container justifyContent="flex-end">
            <Grid
              item
              style={{ width: "450px", overflowX: "auto" }}
              className={style.scrollbar}
            >
              <Grid
                container
                //spacing={2}
                className="d-flex justify-content-end align-items-center"
              >
                <Grid className="mr-2">
                  <Typography>Rows per page:</Typography>
                </Grid>
                <Grid>
                  <FormControl variant="standard">
                    <Select
                      value={pageSize}
                      onChange={(e) => {
                        setPageSize(Number(e.target.value));
                        dispatch(
                          getAllScheduleEventBetweenDates(
                            startDate,
                            endDate,
                            Number(e.target.value),
                            pageNumber
                          )
                        );
                      }}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid className="mx-2">
                  {state.scheduledEventsList.eventsList.length ? (
                    <Typography>
                      {`1-${state.scheduledEventsList.eventsList.length}`}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid className="d-flex align-items-center">
                  <IconButton
                    onClick={() => {
                      if (pageNumber > 0) {
                        const previousPage = pageNumber - 1;
                        setPageNumber(pageNumber - 1);
                        //setpageSize(10);
                        dispatch(
                          getAllScheduleEventBetweenDates(
                            startDate,
                            endDate,
                            pageSize,
                            previousPage
                          )
                        );
                      }
                    }}
                    disabled={pageNumber === 0}
                    size="large"
                  >
                    <ChevronLeftRounded />
                  </IconButton>
                  <Typography>{pageNumber + 1}</Typography>
                  <IconButton
                    onClick={() => {
                      const nextPage = pageNumber + 1;
                      setPageNumber(pageNumber + 1);
                      getAllScheduleEventBetweenDates(
                        startDate,
                        endDate,
                        pageSize,
                        nextPage
                      );
                    }}
                    disabled={
                      state.scheduledEventsList.eventsList.length - pageSize < 0
                    }
                    size="large"
                  >
                    <ChevronRightRounded />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    }
    return (
      <div ref={ref}>
        <Grow
          in={currentScreen === 0}
          mountOnEnter
          unmountOnExit
          style={{ transformOrigin: "0 0 0" }}
          {...(currentScreen === 0 ? { timeout: 1000 } : {})}
        >
          <div>
            <Grid
              container
              spacing={2}
              sx={{
                alignItems: "center",
                justifyContent: "space-between",
                padding: " 16px",
              }}
            >
              <Grid item xs={12} sm={6} md={8} lg={6}>
                <Grid
                  container
                  spacing={4}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <Grid item>
                    <Typography variant="h6">Events</Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setCurrentScreen(1)}
                      startIcon={<AddIcon />}
                    >
                      Schedule Event
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={6} className="my-1">
                <Grid
                  container
                  alignItems="center"
                  spacing={1}
                  justifyContent="flex-end"
                  //   className={style.date}
                >
                  <Grid item>
                    <FormControl
                      variant="outlined"
                      style={{ maxWidth: "150px" }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          // autoOk
                          // variant="inline"
                          // okLabel={false}
                          // cancelLabel={false}
                          inputFormat="dd/MM/yyyy"
                          label="Start Date"
                          // size="small"
                          maxDate={new Date(endDate)}
                          value={startDate}
                          // error={false}
                          renderInput={(params) => <TextField {...params} />}
                          onChange={(value: any) => {
                            setStartDate(value);
                            dispatch(setEventsListLoading(true));
                            console.log(value);
                          }}
                          // inputVariant="outlined"
                          // TextFieldComponent={(props) => (
                          //   <TextField variant="outlined" {...props} />
                          // )}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>
                  <Grid item></Grid>
                  <Grid item>
                    <FormControl
                      variant="outlined"
                      style={{ maxWidth: "150px" }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          // autoOk
                          //variant="inline"
                          // okLabel={false}
                          // cancelLabel={false}
                          // error={false}
                          inputFormat="dd/MM/yyyy"
                          label="End Date"
                          // size="small"
                          minDate={new Date(startDate)}
                          value={endDate}
                          renderInput={(params) => <TextField {...params} />}
                          onChange={(value: any) => {
                            setEndDate(value);
                            dispatch(setEventsListLoading(true));
                          }}
                          // inputVariant="outlined"
                          // TextFieldComponent={(props) => (
                          //   <TextField variant="outlined" {...props} />
                          // )}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider />
            <TableWrapper>
              <Paper>
                <Grid
                  item
                  xs={12}
                  style={{ backgroundColor: "transparent" }}
                  className="py-2"
                >
                  <Grid className="d-flex align-items-center">
                    <TextField
                      type="search"
                      fullWidth
                      value={searchEvent}
                      variant="outlined"
                      placeholder="Search events by name"
                      size="small"
                      onChange={(e) => {}}
                      className="ml-3"
                      sx={{ visibility: "hidden" }}
                    />

                    <Grid>
                      <IconButton
                        onClick={() => {
                          dispatch(
                            getAllScheduleEventBetweenDates(
                              startDate,
                              endDate,
                              pageSize,
                              pageNumber
                            )
                          );
                        }}
                        size="large"
                      >
                        <Tooltip title="Refresh">
                          <RefreshIcon />
                        </Tooltip>
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
                {state.scheduledEventsList.loading
                  ? ListSkeleton()
                  : renderLiveSessionsListBetween()}
              </Paper>
            </TableWrapper>
          </div>
        </Grow>
        <Grow
          in={currentScreen === 1}
          mountOnEnter
          unmountOnExit
          style={{ transformOrigin: "0 0 0" }}
          {...(currentScreen === 1 ? { timeout: 1000 } : {})}
        >
          <CreateEvent
            isEditing={states.editing}
            editItem={states.editItem}
            onClickBack={onClickBack}
            startDate={startDate}
            endDate={endDate}
          />
        </Grow>
      </div>
    );
  }
);
